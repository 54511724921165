<template>
  <div class="mx-4 mt-4">
    <div class="card col-12">
      <div class="mt-4">
        <iframe
          src="https://gestion.lanielstlaurent.ca/grafana/d/be588jxhyldz4f/lectures-de-caisses?orgId=1&from=2024-12-01T00:00:00.000Z&to=2024-12-07T23:59:59.999Z&timezone=browser&var-service_centers=$__all&var-establishments=$__all&var-cafeterias=$__all&kiosk"
          style="width: 100%; min-height: calc(100vh - 170px)"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style></style>
